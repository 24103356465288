<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent>
        <b-card-code>
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Language"
                rules="required"
              >
                <b-form-group
                  label="Select Languages"
                  label-for="mc-select-language"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    v-model="selectedLanguages"
                    class="language"
                    :disabled="true"
                    placeholder="Select Languages"
                    :state="errors.length > 0 ? false : null"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="selectedLanguages"
                    :reduce="val => val.value"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card-code>

        <app-collapse
          v-for="(item, index) in trendingEditForm.trendingLanguages"
          :id="item.id"
          :key="index"
          accordion
          :type="collapseType"
        >
          <app-collapse-item :title="`${item.language}`">
            <b-row>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  :name="`${item.language} title`"
                  rules="required|max:60|min:3"
                >
                  <b-form-group
                    label="Banner Title"
                    label-for="mc-banner-title"
                  >
                    <b-form-input
                      v-model="trendingEditForm.trendingLanguages[index].title"
                      class="title"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter banner title"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col v-if="!trendingEditForm.isDeal" md="6">
                <validation-provider
                  #default="{ errors }"
                  :name="`${item.language} sub-title`"
                  rules="required|max:60|min:3"
                >
                  <b-form-group label="Sub title" label-for="mc-sub-title">
                    <b-form-input
                      v-model="
                        trendingEditForm.trendingLanguages[index].sub_title
                      "
                      class="sub_title"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter banner sub-title"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col v-if="!trendingEditForm.isDeal" md="12">
                <validation-provider
                  #default="{ errors }"
                  :name="`${item.language} description`"
                  rules="required"
                >
                  <b-form-group label="Description" label-for="mc-description">
                    <template>
                      <quill-editor
                        v-model="
                          trendingEditForm.trendingLanguages[index].description
                        "
                        :options="snowOption"
                      />
                    </template>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>

        <b-row col>
          <b-col md="7" class="mt-2">
            <div class="helper" />
            <div
              class="drop display-inline align-center"
              @dragover.prevent
              @drop="onDrop"
            >
              <div class="helper" />
              <label
                v-if="!imagePreview && !trendingEditForm.trending_media"
                class="btn btn-primary display-inline"
              >
                SELECT OR DROP AN IMAGE
                <input
                  type="file"
                  accept="image/*"
                  name="image"
                  @change="onChange"
                >
              </label>
              <div
                v-if="imagePreview"
                class="display-inline align-center"
                :class="{ image: true }"
              >
                <img :src="imagePreview" alt="Image" class="img mt-2">
                <br>
                <br>
                <div class="helper" />
                <label class="btn btn-primary display-inline">
                  REPLACE
                  <input
                    type="file"
                    accept="image/*"
                    name="image"
                    @change="onChange"
                  >
                </label>
              </div>
              <div
                v-if="trendingEditForm.trending_media && !imagePreview"
                class="display-inline align-center"
                :class="{ image: true }"
              >
                <img
                  :src="trendingEditForm.trending_media"
                  alt="Image"
                  class="img mt-2"
                >
                <br>
                <br>
                <div class="helper" />
                <label class="btn btn-primary display-inline">
                  REPLACE
                  <input
                    type="file"
                    accept="image/*"
                    name="image"
                    @change="onChange"
                  >
                </label>
              </div>
            </div>
          </b-col>

          <b-col v-if="!trendingEditForm.isDeal" md="5">
            <b-form-checkbox
              v-model="trendingEditForm.url"
              name="check-button"
              switch
              inline
              class="mt-4"
            >
              URL
            </b-form-checkbox>
            <validation-provider
              v-if="trendingEditForm.url"
              #default="{ errors }"
              name="Link"
              rules="required|max:60|min:3|url"
            >
              <b-form-group
                v-if="trendingEditForm.url"
                label="Link"
                label-for="mc-banner-title"
                class="mt-2"
              >
                <b-form-input
                  v-model="trendingEditForm.link"
                  class="title"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter link address"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-col md="12" class="demo-inline-spacing mt-2">
          <b-button
            :disabled="loading"
            variant="primary"
            type="submit"
            @click="editTrending"
          >
            Submit
            <div v-if="loading" class="spinner-border spinner-border-sm" />
          </b-button>
          <b-button
            variant="secondary"
            :to="{
              name:
                checkLoginRole() === RoleEnum.SuperAdmin || checkAccess.view
                  ? 'trending-now'
                  : 'dashboard'
            }"
          >
            Cancel
          </b-button>

          <!-- <b-button v-if="!checkAccess.view"
                      variant="secondary"
                      :to="{name: 'dashboard'}"
            >
              Cancel
            </b-button> -->
        </b-col>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BFormCheckbox,
  BButton
} from "bootstrap-vue";
import languageService from "@/services/language/language.service";
import {
  RoleEnum,
  resConditionCheck,
  layoutOptions,
  ImageError
} from "@/helpers/constant";
import errorResponseHandler from "@/services/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import TrendingService from "@/services/banner-management/trending/trending.service";
import checkLoginRole from "@/helpers/checkLoginRole";
import accessRightCheck from "@/helpers/accessRightCheck";

// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { required } from "@core/utils/validations/validations";

export default {
  components: {
    BCardCode,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    AppCollapse,
    AppCollapseItem,
    quillEditor,
    BFormCheckbox,
    BButton
  },

  data() {
    return {
      selectedLanguages: [],
      trendingEditForm: {
        trending_media: "",
        link: "",
        url: false,
        trendingLanguages: []
      },
      collapseType: "margin",
      checkLoginRole,
      imagePreview: "",
      snowOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike", "align", "link"],
            [{ list: "ordered" }, { list: "bullet" }]
          ]
        }
      },

      required,
      layoutOptions,
      loading: false,
      RoleEnum,
      staticObject: {
        title: "",
        sub_title: "",
        description: "",
        language: "",
        language_id: "",
        id: ""
      },
      uploadVideo: false,
      src: "",
      checkAccess: {},
    };
  },

  mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name);
    this.getLanguageDropdown();
    this.getTrendingById(this.$route.params.id);
  },

  methods: {
    async getLanguageDropdown() {
      try {
        const res = await languageService.getLanguageDropdown();
        if (resConditionCheck(res.status) && res.data.data) {
          this.selectedLanguages = res.data.data.map(e => ({
            label: e.name,
            id: e.id
          }));
        }
      } catch (error) {
        const errorData = errorResponseHandler(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      }
    },

    async getTrendingById(id) {
      try {
        const res = await TrendingService.getTrendingById(id);
        if (resConditionCheck(res.status) && res.data.data) {
          this.trendingEditForm.url = res.data.data.url;
          this.trendingEditForm.link = res.data.data.link;
          this.trendingEditForm.isDeal = res.data.data.onlyImage;
          for (let i = 0; i < this.selectedLanguages.length; i += 1) {
            this.trendingEditForm.trendingLanguages.push({
              ...this.staticObject,
              language: this.selectedLanguages[i].label,
              language_id: this.selectedLanguages[i].id
            });
            this.trendingEditForm.trendingLanguages[i].title = res.data.data.trendingLanguages[i] && res.data.data.trendingLanguages[i].title ? res.data.data.trendingLanguages[i].title : '';
            this.trendingEditForm.trendingLanguages[i].sub_title = res.data.data.trendingLanguages[i] && res.data.data.trendingLanguages[i].subTitle ? res.data.data.trendingLanguages[i].subTitle : '';
            this.trendingEditForm.trendingLanguages[i].description = res.data.data.trendingLanguages[i] && res.data.data.trendingLanguages[i].description ? res.data.data.trendingLanguages[i].description : '';
            this.trendingEditForm.trendingLanguages[i].id = res.data.data.trendingLanguages[i] && res.data.data.trendingLanguages[i].id ? res.data.data.trendingLanguages[i].id : '';
            this.trendingEditForm.trending_media = res.data.data.trendingMedia;
          }
        }
      } catch (error) {
        const errorData = errorResponseHandler(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      }
    },

    async editTrending() {
      const valid = await this.$refs.simpleRules.validate();

      if (!this.trendingEditForm.trending_media) {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
        }, 3000);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: ImageError.Banner_Not_Found,
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      }
      if (valid && this.trendingEditForm.trending_media) {
        this.loading = true;
        try {
          const formdata = new FormData();
          formdata.append(
            "trending_media",
            this.trendingEditForm.trending_media
          );
          formdata.append("link", this.trendingEditForm.link);
          formdata.append("url", this.trendingEditForm.url);
          formdata.append(
            "trending_language",
            JSON.stringify(this.trendingEditForm.trendingLanguages)
          );
          const res = await TrendingService.editTrending(
            this.$route.params.id,
            formdata
          );
          this.loading = false;
          if (resConditionCheck(res.status) && res.data.data) {
            this.$router.push("/trending/list");
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "CheckIcon",
                variant: "success"
              }
            });
          }
        } catch (error) {
          setTimeout(() => {
            this.loading = false;
          }, 3000);
          const errorData = errorResponseHandler(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger"
            }
          });
        }
      }
    },

    onDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      const { files } = e.dataTransfer;
      this.createFile(files[0]);
    },

    onChange(e) {
      const { files } = e.target;
      this.createFile(files[0]);
    },

    createFile(file) {
      if (
        (file.type === "image/jpeg"
          || file.type === "image/jpg"
          || file.type === "image/png"
          || file.type === "image/gif"
          || file.type === "video/mp4")
        && file.size <= 1048576
      ) {
        this.trendingEditForm.trending_media = file;
        this.imagePreview = URL.createObjectURL(file, file.name);
      } else {
        this.trendingEditForm.trending_media = null;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Only JPG, JPEG, GIF or PNG Allowed with Max Size Of 1 MB",
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      }
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style>
.ql-editor {
  min-height: 150px;
  max-height: 350px;
}
</style>

<style>
.dropBtn {
  background-color: #d3394c;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  padding: 15px 35px;
  position: relative;
}

.dropBtn:hover {
  background-color: #722040;
}

input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.align-center {
  text-align: center;
}

/* .helper {
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  width: 0;
} */

.hidden {
  display: none !important;
}

.hidden.image {
  display: inline-block !important;
}

.display-inline {
  display: inline-block;
  vertical-align: middle;
}

.img,
#video {
  border: 1px solid #f6f6f6;
  display: inline-block;
  width: 400px;
  height: 250px;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  padding: 0 10px;
}

.video {
  border: 1px solid #f6f6f6;
  display: inline-block;
  height: auto;
  width: auto;
  max-height: 250px;
  max-width: 400px;
}

.drop {
  background-color: #f2f2f2;
  border: 4px dashed #ccc;
  background-color: #f6f6f6;
  border-radius: 2px;
  height: 400px;
  width: 600px;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
